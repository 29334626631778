.container {
  display: flex;
}

.container:not(:first-of-type) {
  padding-top: 18px;
}

.container:last-of-type {
  padding-bottom: 18px;
}

.inputWrapper {
  width: 100%;
}

.anchor {
  text-decoration: none;
}

.label {
  color: #000;
  cursor: pointer;
  width: 100%;
  min-width: 0;
  text-decoration: none;
  transition: color .2s;
}

.label:hover {
  color: #444;
}

@media (hover: none) {
  .label:hover {
    color: #000;
  }
}

.label:hover {
  text-decoration: underline;
}

.disabled {
  pointer-events: none;
}

.count {
  color: #666;
  margin-top: 3px;
  margin-left: 9px;
  font-size: 14px;
  font-weight: 300;
}
