.container {
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 300;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
}

.stickyHeader {
  transition: 0.3s box-shadow ease;
  box-shadow: 0 1px 5px 1px rgba(34, 34, 34, 0.1);
}

.headings {
  display: flex;
  flex-direction: column;
}

.heading {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-bottom: 9px;
}

.subheading {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #666;
  margin-top: -4.5px;
}

.buttonContainer {
  width: 170px;
}