.wrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  margin: 9px 0 18px 0;
}
.wrapper::-webkit-scrollbar {
  display: none;
}

.inner {
  border-spacing: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
}

.clearAll {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  padding: 0 9px;
  flex: 1 0 auto;
}