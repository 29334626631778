.modal {
  height: 100%;
  background: #fff;
  overflow: scroll;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px;
  border: none;
  background: none;
  overflow: hidden;
  outline: none;
}

.closeIcon {
  height: 20px;
  color: #888;
}