.topSentinel {
  margin-top: 9px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  width: 100vw;
  padding: 9px 18px;
  margin-top: 27px;
  margin-left: calc(-1 * var(--sassy-layout-offset));
  position: sticky;
  top: 0;
  background-color: #fff;
  height: 58px;
  transition: 0.3s transform ease;
  z-index: 100;
}
.wrapper:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s opacity ease;
  box-shadow: 0 1px 5px 1px rgba(34, 34, 34, 0.1);
  opacity: 0;
}

.stickyMode:after {
  opacity: 1 !important;
}

.stickyMode.hideMode {
  transform: translateY(-100%);
}

.cell {
  z-index: 200;
}

.resultsCount {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.resultsCount.bottomSpacer {
  margin-bottom: 18px;
}

.button {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  height: 40px;
  border: 1px solid #bbb;
  color: #222;
  background: #fff;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.filterIcon {
  width: 13px;
  margin-right: 18px;
}