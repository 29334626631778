.container {
  color: #888;
  overflow: auto;
}

.inputWrapper {
  background: #fbfbfb;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 300;
}

.button {
  padding-bottom: 18px;
  display: block;
}

.searchInput {
  color: #222;
  background: #fff;
  margin: 18px 0;
}
