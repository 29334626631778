.container {
  border-bottom: 1px solid #ddd;
  padding: 2px 18px 2px 2px;
  position: relative;
}

.facetContent {
  background: #f3f3f3;
  background: inherit;
  padding: 0 0 0 18px;
}

.facetContent[data-state="collapsed"] {
  visibility: hidden;
}

.facetContent[data-state="expanded"] {
  overflow: visible;
}

.facetContainer {
  margin-left: 2px;
}

.titleWrapper {
  color: #222;
  font-size: 14px;
  font-weight: 300;
  padding: 18px 0 18px 18px !important;
}

.titleWrapper:hover {
  color: #222;
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  flex-basis: 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.title:hover {
  color: #222;
}

.button {
  padding-bottom: 18px;
  display: block;
}

.abTestSelectedValue {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  text-transform: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.abTestSelectedValue .dimension {
  padding-left: 3px;
}
