.container {
  margin: 18px 0;
  font-size: 16px;
  font-weight: 300;
}

.clear:before {
  content: "|";
  margin: 0 9px;
}
