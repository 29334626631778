.container {
  display: flex;
  flex-wrap: wrap;
}

.colorSelect {
  flex-basis: 14.2857142857%;
  padding-bottom: 14%;
  position: relative;
}

.swatch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100%;
  border: 1px solid #ddd;
  appearance: none;
  pointer-events: auto;
  outline-offset: 1px;
  height: calc(100% - 18px);
  width: calc(100% - 18px);
  margin: 9px;
}
.swatch.selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #888;
}

.disabled {
  pointer-events: none;
}