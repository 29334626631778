button.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  padding-top: 18px;
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;
}
button.container:last-of-type {
  padding-bottom: 18px;
}
button.container a {
  text-decoration: none;
}
button.container:hover {
  color: initial;
}
button.container:hover {
  color: #444;
}
@media (hover: none) {
  button.container:hover {
    color: #000;
  }
}
button.container:first-of-type {
  padding: 0 0 18px;
}

.disabled {
  pointer-events: none;
  color: #888;
}

.indent {
  margin-left: 18px;
  padding-right: 18px;
  width: calc(100% - 18px);
}

.text:hover {
  color: #222;
  text-decoration: underline;
}

.selected .text {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
}
.selected .text:hover {
  color: initial;
}

.count {
  font-size: 12px;
  font-weight: 300;
  margin-top: 3px;
  padding-left: 9px;
  color: #666;
}