.wrapper {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  margin: 9px 0 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
  overflow: scroll hidden;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.inner {
  border-spacing: 0;
  align-items: center;
  margin: 0;
  display: inline-flex;
}

.clearAll {
  flex: 1 0 auto;
  padding: 0 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
