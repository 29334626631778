.container {
  flex-wrap: wrap;
  display: flex;
}

.colorSelect {
  flex-basis: 14.2857%;
  padding-bottom: 14%;
  position: relative;
}

.swatch {
  appearance: none;
  pointer-events: auto;
  outline-offset: 1px;
  border: 1px solid #ddd;
  border-radius: 100%;
  width: calc(100% - 18px);
  height: calc(100% - 18px);
  margin: 9px;
  position: absolute;
  inset: 0;
}

.swatch.selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #888;
}

.disabled {
  pointer-events: none;
}
