.container {
  z-index: 300;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 18px;
  display: flex;
  position: sticky;
  top: 0;
}

.stickyHeader {
  transition: box-shadow .3s;
  box-shadow: 0 1px 5px 1px #2222221a;
}

.headings {
  flex-direction: column;
  display: flex;
}

.heading {
  letter-spacing: -.5px;
  margin-bottom: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.subheading {
  color: #666;
  margin-top: -4.5px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.buttonContainer {
  width: 170px;
}
