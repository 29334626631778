.filterItemWrapper {
  padding-right: 9px;
}

.colorBubble {
  border: 1px solid #ddd;
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 3px;
}

.dimension {
  padding-left: 3px;
}

.filterItemContent {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  padding-right: 9px;
}