button.button {
  margin-bottom: 9px;
  padding-bottom: 9px;
  font-size: 16px;
  font-weight: 300;
  display: block;
}

button.buttonSpacingTop {
  margin-top: 9px;
  padding-top: 9px;
}
