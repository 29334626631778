.container {
  padding: 18px 0;
  display: flex;
}

.divider {
  align-self: center;
  margin: 0 18px;
}

.error {
  color: #c00;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 300;
}

.arrow {
  width: 16px;
  height: 100%;
}

.inputRangeButton {
  flex-shrink: 0;
  margin-left: 9px;
  border-width: 1px !important;
  padding: 0 9px !important;
}
