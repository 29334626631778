.modal {
  background: #fff;
  height: 100%;
  overflow: scroll;
}

.close {
  background: none;
  border: none;
  outline: none;
  padding: 18px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.closeIcon {
  color: #888;
  height: 20px;
}
