.filterItemWrapper {
  padding-right: 9px;
}

.colorBubble {
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  display: inline-block;
}

.dimension {
  padding-left: 3px;
}

.filterItemContent {
  padding-right: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
